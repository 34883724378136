<template>
  <div class="client-menu-button">
    <template v-if="menuVO.state === 2">
      <van-button
          size="mini"
          :key="menuVO.id"
          :class="{'need-to-approval-button': menuVO.authToApprove,
          'only-watch-approval-button': !menuVO.authToApprove}"
          @click.stop="onClicked"
      >
        {{ menuVO.count > 0 ? menuVO.name + ` x ` + menuVO.count : menuVO.name }}
        <!--            自定义图标-->
        <template slot="icon">
          <img v-if="menuVO.authToApprove" :src="require('@/assets/images/approval_notice.svg')"
          >
          <img v-else-if="menuVO.authToApprove === false" :src="theme==='DARK_MODE'?require('@/assets/images/approval_watch_night.svg'):
          require('@/assets/images/approval_watch.svg')">
        </template>
      </van-button>
    </template>
    <template v-else>
      <van-button
          :class="{'finished': menuVO.state === 1}"
          size="mini"
          @click.stop="onClicked"
          :key="menuVO.id">
        {{ menuVO.count > 0 ? menuVO.name + ` x ` + menuVO.count : menuVO.name }}
      </van-button>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    menuVO: {
      type: Object,
      default: () => {
      }
    },
    onButtonClicked: {
      type: Function,
      default: () => {
      }
    },
  },
  methods: {
    onClicked() {
      this.onButtonClicked()
    }
  },
  created() {
    this.theme = this.$theme;
  },
  name: "ClientMenuButton"
}
</script>

<style scoped lang="less">
.van-button {
  color: var(--titleColor);
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
  border: none;
  color: var(--red);
  background-color: var(--separatorColor);
  font-weight: 700;

  margin-left: 0;
  margin-right: 10px;
  margin-top: 8px;
}


.van-button.finished {
  color: var(--green);
}

.client-menu-button {
  display: inline-block;
}

.need-to-approval-button {
  color: #267bfc;
}

.van-button__icon {
  width: 16px;
  height: 16px;

  img {
    width: 100%;
    height: 100%;
  }

  .icon {
    width: 100%;
    height: 100%;
  }

}

.only-watch-approval-button {
  color: var(--buttonTextColor);
}
</style>
