<template>
  <div class="custom-filter">
    <div class="filter-content">
      <div class="checkout-wrap">
        <p class="title">症状</p>
        <checkout-group
            v-model="_search.symptoms"
            group-key="symptoms"
            :list="dict.symptoms"
        />
      </div>
      <div class="checkout-wrap">
        <p class="title">服务状态</p>
        <checkout-group
            v-model="_search.states"
            group-key="state"
            :list="dict.serviceStatus"
        />
      </div>
      <div class="checkout-wrap">
        <p class="title">风险类型</p>
        <checkout-group v-model="_search.risks" group-key="risk" :list="dict.risk"/>
      </div>
      <div class="checkout-wrap">
        <p class="title">阶段</p>
        <checkout-group
            v-model="_search.stages"
            group-key="stage"
            :list="dict.stage"
        />
      </div>
      <div class="checkout-wrap">
        <p class="title">待审核</p>
        <checkout-group
            v-model="_search.approvalApplicationTypes"
            group-key="audit"
            :list="dict.audit"
        />
      </div>
      <van-field
          label="工作室"
          placeholder="请选择"
          input-align="right"
          readonly
          is-link
          :value="orgNames"
          class="van-hairline--bottom orgCell"
          @click="showOrgSelector = true"
      />
    </div>

    <div class="btn-group">
      <van-button @click="reset">重置</van-button>
      <van-button color="var(--mainColor)" @click="onConfirm">确认</van-button>
    </div>
    <select-user
        title="选择工作室"
        isSelectedWorkRoom
        formData="?showPeople=false"
        get-container=".van-dropdown-menu"
        :show="showOrgSelector"
        :isRadio="false"
        :selectedUser="this._search.givenOrgIds"
        @close="showOrgSelector = false"
        @confirm="onOrgConfirm"
    />
  </div>
</template>

<script>
import CheckoutGroup from "@/components/checkbox/CheckoutGroup.vue";
import SelectUser from "@/components/SelectUser";

export default {
  components: {
    CheckoutGroup,
    SelectUser,
  },
  props: ["search"],
  data() {
    return {
      dict: {
        symptoms: [],
        serviceStatus: [],
        risk: [],
      },

      orgNames: "",

      showOrgSelector: false,
    };
  },
  computed: {
    _search: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit("update:search", val);
      },
    },
  },
  async created() {
    const {symptoms, serviceStatus, risk} = await this.$getDict();
    this.dict = {
      symptoms: symptoms.children,
      serviceStatus: serviceStatus.children,
      risk: risk.children,
      audit: [
        {
          "id": "100086",
          "code": "4",
          "name": "粉丝群报告审核",
        },
        {
          "id": "100087",
          "code": "1",
          "name": "转二阶段审核",
        },
        {
          "id": "100088",
          "code": "3",
          "name": "终止审核",
        },
        {
          "id": "100089",
          "code": "2",
          "name": "结单审核",
        }
      ],
      // 阶段
      // 待分配，破冰，一阶段，二阶段
      stage: [
        {
          "id": "10086",
          "code": "0",
          "name": "待分配",
        },
        {
          "id": "10087",
          "code": "1",
          "name": "破冰",
        },
        {
          "id": "10088",
          "code": "2",
          "name": "一阶段",
        },
        {
          "id": "10089",
          "code": "3",
          "name": "二阶段",
        }
      ]

    };
  },
  methods: {
    onOrgConfirm(val) {
      this._search.givenOrgIds = val.map((item) => item.id);
      const [org1] = val;
      console.log(org1);
      if (val.length == 0) {
        this.orgNames = "";
      } else if (val.length == 1) {
        this.orgNames = org1.label;
      } else {
        this.orgNames = `${org1.label}...共个${val.length}工作室`;
      }
    },
    reset() {
      this._search.symptoms = [];
      this._search.states = ["1"];
      this._search.risks = [];
      this._search.givenOrgIds = [];
      this._search.approvalApplicationTypes = [];
      this._search.stages = [];
      this.orgNames = "";
    },
    onConfirm() {
      this.$emit("search");
    },
  },
};
</script>

<style scoped lang="less">
.custom-filter {
  height: 60vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  .filter-content {
    flex-grow: 1;
    overflow-y: auto;

    .checkout-wrap {
      padding: 0 16px;

      .title {
        font-size: 16px;
        font-weight: 600;
        margin: 12px 0 10px;
      }
    }

    .van-cell {
      margin-bottom: 10px;
      border-top: 1px solid var(--separatorColor);
      border-bottom: 1px solid var(--separatorColor);

      .van-cell__title {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .orgCell .van-cell__title {
      flex: 0 0 auto;
    }
  }

  .btn-group {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > .van-button {
      flex: 1;
    }
  }
}
</style>
