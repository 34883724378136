<template>
    <div class="empty" :style="$theme == 'SUN_MODE' ? '' : 'opacity: 0.6'">
        <img :src="require('@/assets/img/empty.png')" />
        <span class="empty-text">{{ title }}</span>
    </div>
</template>

<script>
export default {
    props: ["title"],
};
</script>

<style scoped lang="less">
.empty {
    margin-top: 100px;
    position: relative;
    text-align: center;
    img {
        width: 200px;
    }
    .empty-text {
        position: absolute;
        bottom: 18px;
        left: 50%;
        transform: translateX(-50%);
        color: #999;
    }
}
</style>
