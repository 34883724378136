<template>
  <div class="customer-card">
    <div class="card-header">
      <van-image
          fit="cover"
          class="card-img"
          :src="client.portrait || require('@/assets/images/defaultPhoto.png')"
      />
      <div class="card-content">
        <div class="card-info">
          <div class="name">
            <span>{{ client.nickName }}</span>
            <span v-if="client.name">（{{ client.name }}）</span>
          </div>
          <div>阶段：{{ client.stageVo.stageDesc }}</div>
          <div>
                        <span style="color: var(--secondMainColor); margin-right: 5px">
                            {{ client.master.toString() }}
                        </span>
            <span>
                            {{ client.consultantList.join("  ") }}
                        </span>
          </div>
          <van-tag v-if="client.risk" plain round color="#205AF9">
            {{ riskMap[client.risk] }}
          </van-tag>
        </div>
        <div class="card_bottom">
          {{ parseLastDate(client.lastTime) + "  " + client.behavior }}
        </div>
      </div>
    </div>
    <div
        :class="{
      'card-footer-group-wrap': client.approvalMenus?(client.approvalMenus.length + client.menuVoList.length) > 3 : true,
      'card-footer-group-nowrap': client.approvalMenus?(client.approvalMenus.length + client.menuVoList.length) <= 3 : false
    }">
      <div class="card-footer">
        <template v-for="item in client.menuVoList">
          <client-menu-button
              :menu-v-o="item"
              :key="item.id"
              :on-button-clicked="() => $emit('handler', { handle: item, client })"/>
        </template>
      </div>
      <div class="card-footer" v-if="client.approvalMenus">
        <template v-for="item in client.approvalMenus">
          <client-menu-button
              :menu-v-o="item"
              :key="item.id"
              :on-button-clicked="() => $emit('handler', { handle: item, client })"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {parseLastDate} from "@/utils/index";
import ClientMenuButton from "@/views/hoss/client-list/components/ClientMenuButton.vue";

export default {
  components: {ClientMenuButton},
  props: ["client", "stageMap", "riskMap"],
  methods: {
    parseLastDate,
    onMenuButtonClicked({handle, client}) {
      console.log("onMenuButtonClicked", handle, client);
      this.$emit("handler", {handle, client});
    }
  },
};
</script>

<style scoped lang="less">
.customer-card {
  padding: 8px;
  border-bottom: 1px solid var(--separatorColor);

  &:last-child {
    border: none;
  }
}

.card-header {
  display: flex;
  align-items: center;

  .card-img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .card-content {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-info {
      color: var(--subTitleColor);
      font-size: 11px;

      .name {
        font-weight: 700;
        font-size: 15px;
        color: var(--titleColor);
      }

      .van-tag {
        padding: 0 8px;
        margin: 0 4px 4px 0;
        font-size: 10px;
      }

      & > div {
        line-height: 1.5em;
      }
    }

    .card_bottom {
      font-size: 11px;
    }
  }
}

.card-footer {
  .van-button {
    color: var(--titleColor);
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
    border: none;
    color: var(--red);
    background-color: var(--separatorColor);
    font-weight: 700;

    margin-left: 0;
    margin-right: 10px;
    margin-top: 8px;
  }


  .van-button.finished {
    color: var(--green);
  }

  .approval_button {
    display: inline-block;


    .left-top-notice {
      position: absolute;
      top: 0.25rem;
      left: 0;
      width: 0.68rem;
      height: 0.68rem;
    }

    .van-button {
      color: #267bfc;
    }

    .van-button__content {
      display: flex;
      align-items: center;
      //margin-left: 0.05rem;
      //margin-right: 0.05rem;

      .van-button__icon {
        font-size: 0.5rem;
      }

      .van-button__text {
        margin-left: 0.08rem;
      }
    }
  }

}

.card-footer-group-wrap {
  display: flex;
  flex-direction: column;
}

.card-footer-group-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

</style>
