<template>
  <div class="wrap">
    <search-panel ref="search-panel" :option.sync="listOption" @search="onSearch"
                  @changeFilterMenuOpened="changeFilterMenuOpened">
      <template #custom-filter>
        <filtered-panel :search.sync="listOption" @search="closeFilterPanel"/>
      </template>
      <template #tags>
        <template v-if="menusFromServer">
          <div class="stage-group">
            <div class="stage-without-risk-group">
              <template v-for="menuItem in menusFromServer">
                <div class="stage-item"
                     v-if="menuItem.name !== '风险' && menuItem.name !== '待审核'"
                     :key="menuItem.index"
                     :class="{'stage-item-active': conditionIsSelected(menuItem.condition)}"
                     @click.stop="onTagClick(menuItem.index,menuItem.condition)">
                  {{ menuItem.name }}
                </div>
              </template>
            </div>
            <template v-if="riskOrAuditInMenu">
              <div class="three-status-menu-group">
                <template v-for="menuItem in menusFromServer">
                  <template v-if="menuItem.name === '风险'|| menuItem.name === '待审核'">
                    <div class="client-checkbox" @click.stop="onTagClick(menuItem.index,menuItem.condition)"
                         :key="menuItem.index">
                      <img
                          class="client-checkbox-icon"
                          :src="checkBoxCurrentIcon(conditionIsSelected(menuItem.condition),conditionInMenuIsIndeterminate(menuItem.condition))"/>
                      <span>{{ menuItem.name }}</span>
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </div>
        </template>
      </template>
    </search-panel>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model="loading"
          :finished="finished"
          :error.sync="error"
          ref="clientList"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
          :immediate-check="false"
      >
        <template v-if="listOption.orgView">
          <div v-for="(value, key) in orgViewList" :key="key">
            <p class="orgName">{{ key }}</p>
            <div class="card-list">
              <customer-card
                  v-for="item in value"
                  :key="item.id"
                  :client="item"
                  :stageMap="stageMap"
                  :riskMap="riskMap"
                  @click.native="$router.push(`/hoss/stageInfo?clientId=${item.id}`)"
                  @handler="onHandler"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-list">
            <customer-card
                v-for="item in clientList"
                :key="item.id"
                :client="item"
                :stageMap="stageMap"
                :riskMap="riskMap"
                @click.native="$router.push(`/hoss/stageInfo?clientId=${item.id}`)"
                @handler="onHandler"
            />
          </div>
        </template>
        <empty v-if="finished && !refreshing && !Object.keys(orgViewList).length && !clientList.length"
               title="暂无内容"/>
      </van-list>
    </van-pull-refresh>
    <div class="footerButton">
      <van-button icon="plus" type="primary" class="blockButton" block
                  @click="onClickAddClient">
        添加客户
      </van-button>
    </div>
  </div>
</template>

<script>
import {debounce} from "@/utils/index";
import CheckoutGroup from "@/components/checkbox/CheckoutGroup.vue";
import Empty from "@/components/Empty";
import SearchPanel from "./components/SearchPanel";
import FilteredPanel from "./components/FilteredPanel";
import CustomerCard from "./components/CustomerCard";
import {
  getClientInfoListByClientIds,
  getClientList,
  getSearchMenu,
  incompleteCreation,
  queryAndFilterMyClients
} from "@/api/hoss";
import {Toast} from "vant";
// 引入el-checkbox
import 'element-ui/lib/theme-chalk/index.css';
import svg_check_box_indeterminate from "@/assets/images/svg_check_box_indeterminate.svg";
import svg_check_box_checked from "@/assets/images/svg_check_box_checked.svg";
import svg_check_box_unchecked from "@/assets/images/svg_check_box_unchecked.svg";

export default {
  components: {
    SearchPanel, FilteredPanel, CustomerCard, Empty,
    // 'el-checkbox': Checkbox
  },
  data() {
    return {
      loading: false,
      refreshing: false,
      finished: false,
      error: false,
      clientList: [],
      orgViewList: {},
      stageList: [],
      stageMap: {},
      riskMap: {},
      isActiveMenuIndexList: [],
      incompleteCreationList: [],
      menusFromServer: [],
      thereStatusMenuCheckMap: {
        auditChecked: false,
        riskChecked: false
      },
      filterMenuSelected: {
        symptomsSelected: [],
        stateSelected: [],
        stagesSelected: [],
        approvalApplicationTypesSelected: [],
      },
      mayNeedRefreshList: false,
      listOption: {
        key: "",
        pageNum: 0,
        pageSize: 10,
        // 新增参数
        risks: [],
        stages: [],
        symptoms: [],
        states: ["1"],
        givenOrgIds: [],
        onlyConsultant: false,
        orderByUpdateTime: false,
        orgView: false,
        approvalApplicationTypes: [],
      },
      checkBoxUnCheckedIcon: svg_check_box_unchecked,
      checkBoxCheckedIcon: svg_check_box_checked,
      checkBoxIndeterminateIcon: svg_check_box_indeterminate,
      scrollDistance: 0,
      needToRefreshCustomerIds: [],
    };
  },
  computed: {
    riskOrAuditInMenu() {
      for (let i = 0; i < this.menusFromServer.length; i++) {
        if (this.menusFromServer[i].name === "风险" || this.menusFromServer[i].name === "待审核") {
          return this.menusFromServer[i];
        }
      }
      return null;
    },
    conditionIsSelected() {
      return function (conditionObj) {
        var totalSelected = true;
        for (let key in conditionObj) {
          const conditionArray = conditionObj[key];
          if (this.listOption.hasOwnProperty(key)) {
            const targetArray = this.listOption[key];
            // 判断目标数组是否全部包含条件数组
            conditionArray.forEach(item => {
              if (targetArray.indexOf(item.toString()) === -1) {
                totalSelected = false;
              }
            });
          } else {
            totalSelected = false;
            console.error("listOption中没有" + key + "属性");
          }
        }
        return totalSelected;
      }
    },
    conditionInMenuIsIndeterminate() {
      return function (conditionObj) {
        var selectedNum = 0;
        var totalNum = 0;
        for (let key in conditionObj) {
          const conditionArray = conditionObj[key];
          if (this.listOption.hasOwnProperty(key)) {
            const targetArray = this.listOption[key];
            // 判断目标数组是否全部包含条件数组
            conditionArray.forEach(item => {
              if (targetArray.indexOf(item.toString()) !== -1) {
                selectedNum++;
              }
              totalNum++;
            });
          }
        }
        if (selectedNum === 0) {
          return false;
        } else return selectedNum !== totalNum;
      }
    },
    checkBoxCurrentIcon() {
      return function (isSelected, isIndeterminate) {
        if (isSelected) {
          return this.checkBoxCheckedIcon;
        } else if (isIndeterminate) {
          return this.checkBoxIndeterminateIcon;
        } else {
          return this.checkBoxUnCheckedIcon;
        }
      }
    }
  },
  async created() {
    const {state, stage, approvalApplicationTypes, risks, symptoms} = this.$route.query;
    // 新增逻辑
    if (state) this.listOption.states = [state];
    if (stage) this.listOption.stages = [stage];
    if (approvalApplicationTypes) this.listOption.approvalApplicationTypes = approvalApplicationTypes.split(',');
    if (symptoms) this.listOption.symptoms = symptoms.split(',');
    if (risks) this.listOption.risks = risks.split(',');
    const dict = await this.$getDict();
    this.stageList = dict.hossStage.children;
    this.stageMap = {};
    this.stageList.forEach((item) => {
      this.$set(this.stageMap, item.code, item.name);
    });
    this.riskMap = {};
    dict.risk.children.forEach((item) => {
      this.$set(this.riskMap, item.code, item.name);
    });
    const {value: menuValue} = await getSearchMenu()
        .catch(() => {
          Toast.fail("获取筛选条件失败");
        });
    this.menusFromServer = menuValue;
    await this.onRefresh();
  },
  methods: {
    // 记录滚动条位置
    recordScrollDistance(e) {
      this.scrollDistance = e.target.scrollTop;
    },
    queryIncompleteCreation() {
      incompleteCreation()
          .then((res) => {
            if (res.value) {
              if (res.value.list) {
                this.incompleteCreationList = res.value.list;
              }
            }
          })
          .catch(() => {
          });
    },
    onClickAddClient() {
      this.mayNeedRefreshList = true;
      if (this.incompleteCreationList.length > 0) {
        this.$router.push(`/hoss/recordList`);
      } else {
        this.$router.push('/hoss/clientRecord');
      }
    },
    onTagClick(index, conditionObj) {
      const isSelect = this.conditionIsSelected(conditionObj);
      // 设置选中的数组
      if (this.isActiveMenuIndexList.indexOf(index) === -1) {
        this.isActiveMenuIndexList.push(index);
      } else {
        this.isActiveMenuIndexList.splice(this.isActiveMenuIndexList.indexOf(index), 1);
      }
      // 遍历key和value
      for (const key in conditionObj) {
        let conditionArray = conditionObj[key];
        let targetArray = this.listOption[key];
        // 遍历Value数组
        conditionArray.forEach((needCheckedNumber) => {
          // 遍历listOptions对应的key
          // 判断是否包含
          if (targetArray) {
            const indexOfNumber = targetArray.indexOf(needCheckedNumber.toString());
            if (isSelect) {
              if (indexOfNumber !== -1) {
                targetArray.splice(indexOfNumber, 1);
              }
            } else {
              if (indexOfNumber === -1) {
                targetArray.push(needCheckedNumber.toString());
              }
            }
          } else {
            console.log("targetArray不存在", key);
          }
        });
      }
      this.onSearch();
    },
    async queryAndFilterMyClients(isRefresh) {
      const {value} = await queryAndFilterMyClients(this.listOption).catch(() => {
        this.loading = false;
        this.error = true;
        console.error("获取客户列表失败,isRefresh", isRefresh);
        if (isRefresh) {
          this.refreshing = false;
        }
        this.listOption.pageNum = this.listOption.pageNum - 1;
      });
      this.finished = value.lastPage;
      this.loading = false;
      if (this.listOption.orgView) {
        value.list.forEach((item) => {
          if (!this.orgViewList[item.orgName]) {
            this.$set(this.orgViewList, item.orgName, []);
          }
          this.orgViewList[item.orgName].push(item);
        });
      } else {
        this.clientList.push(...value.list);
      }
      if (isRefresh) {
        this.refreshing = false;
      }
    },
    async onLoad() {
      this.listOption.pageNum++;
      console.log("onLoad", this.listOption.pageNum);
      await this.queryAndFilterMyClients(false);
    },
    async onRefresh(isNeedRefreshIncompleteCreation = true) {
      this.listOption.pageNum = 1;
      this.clientList = [];
      this.orgViewList = {};
      await this.queryAndFilterMyClients(true);
      if (isNeedRefreshIncompleteCreation) {
        this.queryIncompleteCreation();
      }
    },
    onSearch: debounce(function () {
      this.finished = false;
      this.loading = true;
      this.$refs["search-panel"]?.$refs["custom-filter"]?.toggle(false);
      this.listOption.pageNum = 0;
      this.clientList = [];
      this.orgViewList = {};
      this.onLoad();
      const vantList = document.querySelector(".van-pull-refresh");
      vantList.scrollTo(0, this.scrollDistance);
    }, 500),

    closeFilterPanel() {
      this.$refs["search-panel"]?.$refs["custom-filter"]?.toggle(false);
    },
    changeFilterMenuOpened() {
      this.filterMenuOpened = true;
    },

    onHandler({handle, client}) {
      console.log(handle, client);
      const {item, id = ""} = handle;
      const {id: clientId, milepostId = ""} = client;
      let path = "/notFound";
      switch (item) {
        case "iceRecord": // 破冰记录
          path = `/hoss/icebreaking?id=${id}&clientId=${clientId}`;
          break;

        case "fan": // 粉丝群报告
        case "fanGroup": // 粉丝群报告审核
          path = `/hoss/fanGroupReport?id=${id}&milepostId=${milepostId}&clientId=${clientId}`;
          break;

        case "record": // 实施记录
          // 当前时间戳
          const now = new Date().getTime();
          path = `/hoss/implementation?id=${id}&milepostId=${milepostId}&date=${now}&clientId=${clientId}`;
          break;

        case "summary": // 复盘总结
          path = `/hoss/reviewSummary?id=${id}&milepostId=${milepostId}&clientId=${clientId}`;
          break;

        case "createGroup": // 组建团队
          path = `/hoss/createTeam?clientId=${clientId}`;
          break;

        case "end": // 终止审核
        case "statement": // 结单审核
        case "changeStage": // 转二阶段审核
          var applicationType = {
            end: 3,
            statement: 2,
            changeStage: 1,
          }[item];
          path = `/hoss/application?applicationId=${id}&clientId=${clientId}&applicationType=${applicationType}`;
          break;

        default:
          break;
      }
      this.$router.push(path);
    },
  },
  deactivated() {
    console.log("deactivated");
  },
  async activated() {
    console.log("activated");
    const vantList = document.querySelector(".van-pull-refresh");
    vantList.scrollTo(0, this.scrollDistance);
    // 刷新列表中的item
    if (this.needToRefreshCustomerIds.length > 0) {
      console.log("检测到需要刷新的客户id", this.needToRefreshCustomerIds);
      const {value} = await getClientInfoListByClientIds(this.needToRefreshCustomerIds).catch((err) => {
        console.log(err);
        return {value: []};
      });
      this.needToRefreshCustomerIds = [];
      console.log("需要刷新的客户信息", value);
      value.forEach((client) => {
        const {id} = client;
        // 替换
        this.clientList.forEach((item, index) => {
          if (item.id === id) {
            this.clientList.splice(index, 1, client);
            console.log("替换成功");
          }
        });
      });
    }

    // 获取url中的参数 refresh
    const {refresh} = this.$route.query;
    if (refresh) {
      if (this.mayNeedRefreshList) {
        await this.onRefresh(false);
        this.mayNeedRefreshList = false;
        // 清除url中的参数
        await this.$router.replace({query: {}});
      }
    }
    this.queryIncompleteCreation();
  },
  beforeRouteLeave(to, from, next) { // 离开当前路由时调用
    // 获取高度-deactivated中获取不到只能在这里获取
    const vantList = document.querySelector(".van-pull-refresh");
    this.scrollDistance = vantList.scrollTop;
    // 获取to中的参数是否携带clientId
    const {query: {clientId}} = to;
    if (clientId) {
      this.needToRefreshCustomerIds.push(clientId);
    }
    next();
  },

};
</script>

<style scoped lang="less">
@active-color: var(--mainColor);
@default-bg: #e9edff;
@disable-color: #999;
@disable-bg: var(--separatorColor);

///deep/ .van-pull-refresh {
//  height: calc(100vh);
//  box-sizing: border-box;
//  overflow: auto !important;
//  padding-bottom: 40px;
//}

.wrap {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  overflow-y: auto;

  .van-list {
    width: 100%;
    min-height: calc(100vh - 72px);
    padding-bottom: 1.5rem;


    .card-list {
      background-color: var(--cardBackgroundColor);
      margin: 0 8px 8px;
      border-radius: 8px;
    }
  }

  .checkout-tag {
    overflow-x: auto;

    /deep/ .checkbox {
      white-space: nowrap;
      color: var(--color);
      padding: 5px 6px;
      vertical-align: bottom;

      &-isActive {
        color: var(--titleColor);
        border: 1px solid var(--mainColor);
      }


    }
  }

  /deep/ .checkbox.checkbox-isActive {
    color: var(--titleColor);
    border: 1px solid var(--mainColor);
  }

  .orgName {
    padding: 5px 10px;
    margin: 0;
    position: sticky;
    top: 0;
    background-color: var(--background);
    z-index: 1;
  }

  .stage-group {
    // 左右排列
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    max-lines: 1;

    .stage-without-risk-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: -5px;

      .stage-item {
        border-radius: 3px;
        border: 1px solid var(--mainColor);
        padding: 8px 6px;
        color: var(--mainColor);
        font-size: 12px;
        transition: all 0.1s;
        margin: 0 5px 0 0;
      }

      .stage-item-active {
        background-color: var(--buttonSelectColor);
        color: var(--titleColor);
        border: 1px solid var(--buttonSelectColor);
      }

    }

    .three-status-menu-group {
      // 左右排列
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .risk-item {
      padding: 8px 7px 6px;
      background-color: var(--subTitleColor);
      border-radius: 4px;
      color: white;
      font-weight: bold;
      font-size: 12px;
      transition: all 0.1s;
      letter-spacing: 1px;
    }

    .risk-item-active {
      background-color: #e14440;
    }

  }

  .client-checkbox {
    // 左右排列
    display: flex;
    flex-direction: row;
    // 垂直居中
    align-items: center;
    margin-right: 5px;

    .client-checkbox-icon {
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
  }

  .blockButton {
    background: var(--cardBackgroundColor);
    border: 0px;
    border-top: 1px solid var(--background);
    color: var(--mainColor);
    font-size: 15px;

    .van-button__text {
      margin-left: 5px;
      margin-bottom: 0.02rem;
    }

    .van-button__icon {
    }
  }
}

.footerButton {
  width: 100%;
  position: fixed;
  bottom: 0px;
}

.van-pull-refresh {
  overflow: auto !important;
}
</style>
