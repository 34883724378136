<template>
  <div>
    <van-search v-model="_option.key" shape="round" @search="onSearch"/>
    <van-dropdown-menu>
      <van-dropdown-item
          v-model="_option.orderByUpdateTime"
          :options="orderOptions"
          @change="onSearch"
      />
      <van-dropdown-item v-model="_option.orgView" :options="viewOption" @change="onSearch"
      />
      <van-dropdown-item title="筛选" ref="custom-filter" v-model="opened" @close="changeFilterMenuOpened">
        <slot name="custom-filter"></slot>
      </van-dropdown-item>
    </van-dropdown-menu>
    <slot name="tags"></slot>
  </div>
</template>

<script>
import {debounce} from "@/utils";

export default {
  props: ["option"],
  data() {
    return {
      viewOption: [
        {text: "普通视图", value: false},
        {text: "工作室视图", value: true},
      ],
      orderOptions: [
        {text: "最新创建", value: false},
        {text: "最新更新", value: true},
      ],
      opened: false,
    };
  },
  computed: {
    _option: {
      get() {
        return this.option;
      },
      set(val) {
        this.$emit("update:option", val);
      },
    },
  },

  methods: {
    onSearch: debounce(function () {
      this.$emit("search");
    }, 200),
    changeFilterMenuOpened() {
      this.$emit("changeFilterMenuOpened");
      // this.opened = true;
      // 找到值为“筛选”的div class是van-ellipsis
      const filter = document.getElementsByClassName("van-ellipsis");
      for (let i = 0; i < filter.length; i++) {
        if (filter[i].innerText === "筛选") {
          // filter[i].style.color = "#1989fa";
          // class新增一个dropdown-item-opened
          filter[i].parentElement.classList.add("dropdown-item-opened");
          break;
        }
      }
      this.onSearch();
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-dropdown-item__content {
  border-radius: 0 0 15px 15px;
}

.van-search .van-search__content {
  background-color: var(--separatorColor);
}

.filter-wrap {
  height: 50vh;
}

/deep/ .dropdown-item-opened {
  color: var(--mainColor);

}

/deep/ .dropdown-item-opened::after {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: var(--mainColor);
  border-left-color: var(--mainColor);
}


.checkout-tag {
  padding: 0 8px;
  display: flex;
  align-items: center;
}
</style>
